import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Link from 'gatsby-link'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import Cta from '../../components/cta'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact'

export default ({ data, location }) => {
  const post = data.markdownRemark
  const famplats = data.allMarkdownRemark.edges

  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}           
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div
                    className="font-w-400 text-xs-extra-large divlink text-opening-para"
                    dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara }}
                  />
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>

        {famplats ? (
          <section className="bg-white-grey-gradient">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3">
                  {post.frontmatter.section[0].title}
                </h2>
                <div className="pb-2 font-w-400 text-medium">
                  {post.frontmatter.section[0].subtitle}
                </div>
                {famplats.sort((a, b) => a.node.frontmatter.sequence == b.node.frontmatter.sequence ? 0 : 
                  a.node.frontmatter.sequence < b.node.frontmatter.sequence ? -1 : 1).map(({ node }, index) => (
                    <MDBRow className="flex-row" key={index}>
                      <MDBCol md="12" className="pt-5 pb-2">
                        <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-small">
                          {node.frontmatter.familyname}
                        </h3>
                      </MDBCol>
                      {node.frontmatter.platformsbyfamily && (
                        <>
                          {node.frontmatter.platformsbyfamily.sort((a, b) => a.frontmatter.sort == b.frontmatter.sort ? 0 : 
                            a.frontmatter.sort < b.frontmatter.sort ? -1 : 1).map((platform, index) => {
                              return (
                                <Fragment key={index}>
                                  {platform.frontmatter.section.filter(section => section.eol === false).map((details, index) => {
                                      return (
                                        <MDBCol md="4" className="py-3 d-flex " key={index}>
                                          <MDBCard className="flex-fill">
                                            <div style={{ minHeight: '200px', maxHeight: '220px' }}>
                                              <Img 
                                                fluid={details.image.childImageSharp.fluid}
                                                className="m-3"
                                                alt={details.alttext}
                                              />
                                            </div>
                                            <MDBCardBody>
                                              <MDBCardTitle className="text-center title-extra-small font-w-700">
                                                {details.title} <br />
                                              </MDBCardTitle>
                                              <div className="text-center">
                                                {(details.feature && details.viewpage !== false) ? (
                                                  <Link
                                                    className="effect text-medium font-w-400"
                                                    to={platform.fields.slug ? platform.fields.slug : details.link}
                                                  >                                                    
                                                      {platform.fields.slug 
                                                        ? 'View platform page'
                                                        : 'View datasheet'}
                                                  </Link>
                                                ) : (
                                                  <a 
                                                    href={details.document.publicURL}
                                                    target="_blank"
                                                    rel="noopener"
                                                    className="effect text-medium font-w-400 effect"
                                                  >
                                                      View datasheet
                                                  </a>
                                                )}
                                              </div>
                                            </MDBCardBody>
                                          </MDBCard>
                                        </MDBCol>
                                      )
                                    }
                                  )}
                                </Fragment>
                              )
                            })}
                        </>
                      )}
                    </MDBRow>
                  ))}
              </MDBContainer>
            </MDBAnimation>
          </section>
        ) : (
          ''
        )}

        <section className="bg-blue-green-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle="Request a quote"
                ctadescription="The UNICOM Engineering team is standing by to learn about your business and help you identify the technology and services that can transform your solutions and provide a competitive advantage. The quicker we start to engage the faster this can happen. We’ve provided some baseline information above, but we are much more engaging in person."
                ctalink={
                  'https://go.unicomengineering.com/Request-A-Quote.html?pg-name=' +
                  post.frontmatter.section[0].title
                }
                ctalinktext="Request a quote"
              />
            </MDBContainer>
          </MDBAnimation>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }        
    markdownRemark(frontmatter: { name: { eq: "platforms" } }) {
      fields {
        slug
      }
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug          
        openingpara
        section {
          title
          subtitle
          description
          alttext
          image {
            childImageSharp {
              fluid(maxHeight: 450, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          linktext
          link
          feature {
            title
            description
          }
        }
      }
      html
    }
    allMarkdownRemark(filter: {frontmatter: {familyname: {ne: null}, hide: {ne: true}}}, sort: {fields: frontmatter___platformsbyfamily___frontmatter___sort, order: ASC}) {
      edges {
        node {
          frontmatter {
            familyname
            sequence
            platformsbyfamily {
              frontmatter {
                template
                sort
                title
                subtitle
                hide
                foreimage {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                section {
                  title
                  viewpage
                  eol 
                  link
                  linktext
                  document {
                    publicURL
                  }
                  alttext
                  image {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  feature {
                    title
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    }
  }
`
